import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from "react"
import '../styles/main.css';

const githubStyle = {
  color: "#1A1D21"
};

const linkedInStyle = {
  color: "#0e76a8"
};

const instagramStyle = {
  color: "#ee4056"
}


// markup
const IndexPage = () => {
  return (
    <main className="main-container">
        <div className="top-start">
            <h1>Welcome to my <span className="portfolio-text">PORTFOLIO</span></h1>
            <h1>I am a developer. I work in Full Stack Development as well as in Blockchain and Mobile</h1>
            <h2>I am also into music</h2>
        </div>

        <div className="footer">
            <div className="social-panel">
                <div className="frame">
                    <a href="https://github.com/ayusholi" className="btn" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={['fab', 'github-square']} />

                        <i className="fab fa-github" style={githubStyle}></i>
                    </a>
                    <a href="https://www.linkedin.com/in/ayush-oli-368a13142/" className="btn" target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin-in" style={linkedInStyle}></i>
                    </a>
                    <a href="https://www.instagram.com/0_0_ayush" className="btn" target="_blank" rel="noreferrer">
                        <i className="fab fa-instagram" style={instagramStyle}></i>
                    </a>
                    <a href="/cdn-cgi/l/email-protection#8dffe8e8e5e2e2e9ecf4f8fee5cdeae0ece4e1a3eee2e0" className="btn" target="_blank" rel="noreferrer">
                        <i className="far fa-envelope"></i>
                    </a>
                </div>
            </div>
        </div>
    </main>
  )
}

export default IndexPage
